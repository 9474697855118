<!--  -->
<template>
    <div class='page-check'>
        <component-bcg url="loading/bcg.png"></component-bcg>
        <!-- <div class="check-icon"></div>
        <div class="check-foot">
            <div class="pro-notice">
                <span class="text-normal">{{title}}</span>
            </div>
            <div class="pro-progress">
                <div class="pro-content" :style="progressStyle"></div>
            </div>
        </div> -->
        <div class="token-item head">

        </div>
        <div class="token-item foot">
            <div class="foot-item ava"></div>
            <div class="foot-item notice">
                <span class="text-normal">{{title}}</span>
            </div>
            <div class="foot-item pro flex-align-center">
                <div class="body" :style="progressStyle"></div>
            </div>
            
        </div>
    </div>
</template>

<script>
    import api from '@/API/page/app'
    export default {
        name: '',
        props: {},
        data() {
            return {
                appData: new this.appDataClass({key: 'app'}),
                callback: this.$route.query.callback,
                sid: this.$route.query.sid,
                pclient: this.$route.query.pclient,
                hash: this.$route.hash,
                access_token: this.$route.params.access_token,
                refresh_token: this.$route.params.refresh_token,
                access_token_local: null,
                refresh_token_local: null,
                user_token_type: this.PARAMS.user_token_type,
                api: new api(),
                progress: [
                    //1 等待， 2 处理， 3 结束
                    {fun: 'localDataHand',intro: '身份检测'},
                    {fun: 'userTokenCheck', intro: 'token验证'},
                    {fun: 'userTokenRefresh', intro: 'token验证'},
                ],
                proStatus: {
                    localDataHand: {state: 1, errorMsg: this.ERRORCODE['app']['load local data error'], res: null},
                    userTokenCheck: {state: 1, errorMsg: this.ERRORCODE['app']['check token error'], res: null},
                    userTokenRefresh: {state: 1, errorMsg: this.ERRORCODE['app']['unknown auth error'], res: null},
                },
                endLabel: 3,
                handLabel: 2
            };
        },
        methods: {
            async action(list, index){
                list = list || this.progress
                index = index || 0
                if(!list[index]){
                    this.errorHand()
                    return this.errorText ? false : true
                }
                let {fun} = list[index]
                if(this[fun]){
                    this.proStatus[fun].state = this.handLabel
                    let res = await this[fun]()
                    this.proStatus[fun].res = res
                    this.proStatus[fun].state = this.endLabel
                }
                return this.action(list, ++index)
            },
            errorHand(){
                let message = this.errorText
                if(!message) return
                this._common.confirm.open({
                    message,
                    showCancelButton: false,
                    buttons: [
                        {title: '确定', type: 'cancel'}
                    ]
                })
            },
            // step 1.
            async localDataHand(){
                if(this.access_token && this.refresh_token) this.userLogoutSet()
                else{
                    this.access_token_local = this.appData.getItem('access_token')
                    this.refresh_token_local = this.appData.getItem('refresh_token')
                }
                
                return this.delayReturn(true)
            },

            // step 2.
            async userTokenCheck(){
                let {accessToken: access_token} = this
                if(!access_token) return false
                let time = (new Date()).getTime()
                let res = await this.api.checkUserTokenGaotu({noError: true, access_token})
                if(!res){
                    this.userLogoutSet()
                }
                return this.delayReturn(res, time)
            },

            // step 3.
            async userTokenRefresh(){
                let {refreshToken: refresh_token} = this
                if(!refresh_token) return false
                let time = (new Date()).getTime()
                let res = await this.api.userTokenRefreshGaotu({refresh_token})
                if(!res) this.userLogoutSet()
                return this.delayReturn(res, time)
            },

            async delayReturn(res, time){
                let minTime = this.minDelayTime
                let timeNow = (new Date()).getTime()
                time = time || timeNow
                let t = minTime - (timeNow - time)
                await this._common.settimeout(t)
                return res
            },
            async appInit(){
                this.localDataHand()
                await this.userInit()
            },
            async userInit(){
                let usercode = this.appData.getItem('usercode')
                if(!usercode) this.userLogoutSet()
                else{
                    await this.userTokenCheck()
                }
            },
            
            
            userLogoutSet(){
                this._userHand.userLogoutSet()
            },
            callbackSet(){
                if(!this.appData) return
                let {callback, hash} = this
                if(!callback) return
                let url = `${callback}${hash}`
                let quit_callback = url
                this.appData.setItem({quit_callback})
            },
            sidSet(){
                if(!this.appData) return
                let {sid} = this
                if(!sid) return
                sid = `${sid}`
                this.appData.setItem({sid})
            },
            pcClientSet(){
                if(!this.appData) return
                let {pclient} = this
                if(!pclient) return
                pclient = `${pclient}`
                this.appData.setItem({pclient})
            }
            
        },
        created() {
            // this.appInit()
        },
        components: {
        },
        computed: {
            title(){
                return this.errorText || this.TEXT.components.t18
            },
            errorText(){
                let {proStatus: list} = this
                let text = null
                for(let i in list){
                    let {state, res, errorMsg} = list[i]
                    if(state != this.endLabel || res) continue
                    text = errorMsg
                    break
                }
                return text
            },
            progressStyle(){
                let {progressData: pro} = this
                return {
                    width: `${pro * 100}%`
                }
            },
            progressData(){
                let list = this.proStatus
                let pro = 0, step = 1 / this.progress.length
                let endLabel = this.endLabel
                for(let i in list){
                    let {state} = list[i]
                    if(state == endLabel){
                        pro++
                    }
                }
                pro = pro * step
                return pro.toFixed(2)
            },
            accessToken(){
                return this.access_token || this.access_token_local
            },
            refreshToken(){
                return this.refresh_token || this.refresh_token_local
            },
            minDelayTime(){
                if(!this.access_token && !this.refresh_token) return 0
                return 500
            },
            callbackWatch(){
                let {callback, hash} = this
                if(callback || hash) return true
                return false
            }
        },
        watch: {
            $route: {
                handler(){
                    this.access_token = this.$route.params.access_token
                    this.refresh_token = this.$route.params.refresh_token
                    this.callback = this.$route.query.callback
                    this.hash = this.$route.hash
                    this.sid = this.$route.query.sid
                    this.pclient = this.$route.query.pclient
                    this.callbackSet()
                    this.sidSet()
                    this.pcClientSet()
                },
                deep: true,
                immediate: true
            },
            // callbackWatch: {
            //     handler(val){
            //         if(!this.appData) return
            //         let {callback, hash} = this

            //         // if(!val){
            //         //     this.appData.removeItem({quit_callback: true})
            //         //     return
            //         // }
            //         // let quit_callback = val
            //         // this.appData.setItem({quit_callback})
            //     },
            //     deep: true,
            //     immediate: true
            // }
        },
        mounted() {
            this.$emit('tokenLoaded')
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' src='./index.less'></style>