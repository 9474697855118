<template>
    <div class='page-check mind'>
        <component-bcg url="loading/bcg.png"></component-bcg>
        <!-- <div class="check-icon"></div>
        <div class="check-foot">
            <div class="pro-notice">
                <span class="text-normal">{{title}}</span>
            </div>
            <div class="pro-progress">
                <div class="pro-content" :style="progressStyle"></div>
            </div>
        </div> -->
        <div class="token-item head">

        </div>
        <div class="token-item foot">
            <div class="foot-item light"></div>
            <div class="foot-item ava"></div>
            <div class="foot-item notice">
                <span class="text-normal">{{title}}</span>
            </div>
            <div class="foot-item pro flex-align-center">
                <div class="body" :style="progressStyle"></div>
            </div>
            
        </div>
    </div>
</template>
<script>
    import com from '@/views/token/index.vue'

    export default {
        name: '',
        extends: com,
        props: {
        },
        data() {
            return {};
        },
        methods: {},
        created() {
        },
        components: {},
        computed: {},
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang="less" src="../../../../views/token/index.less"></style>
<style lang="less">
    .page-check.mind{
        .background-img-max(url('@{assetsUrlMind}/img/loading/c2.png'));
        .token-item.head{
            .background-img-max(url('@{assetsUrlMind}/img/loading/c1.png'));
        }
        .token-item.foot{
            .foot-item.ava{
                position: absolute;
                width: 65px;
                height: 107px;    
                right: 84px;
                top: -71px;
                .background-img-max(url('@{assetsUrlMind}/img/loading/c4.png'));
            }
            .foot-item.light{
                z-index: 1;
                position: absolute;
                width: 28px;
                height: 30px;    
                right: 161px;
                top: -60px;
                .background-img-max(url('@{assetsUrlMind}/img/loading/light.png'));
            }
        }
    }
</style>