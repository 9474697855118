<template>
    <div class='page-check mind middle'>
        <div class="bcg-shadow">
            <bcg>
                <template v-slot:items>
                    <div class="index-module-item" 
                    v-for="(v, i) in moduleList" :key="i" :class="[v.class, {'disabled': v.disabled}]">
                        <div class="title"></div>
                    </div>
                </template>
            </bcg>
            <div class="token-user-box">
                <div class="stone-box">
                    <div class="stone"></div>
                    <div class="txt"></div>
                </div>
                <div class="user-box-bcg">
                    <div class="user-box">
                        <div class="txt"></div>
                    </div>
                    <div class="level-bcg">
                        <div class="txt"></div>
                    </div>
                    <div class="photo-bcg">
                        <div class="photo"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="middle-bcg">
            <div class="content">
                <div class="a"></div>
                <div class="b">
                    <div class="b_1"></div>
                    <div class="b_2">
                        <div class="star"></div>
                    </div>
                </div>
                <div class="c"></div>
                <div class="d"></div>
                <div class="e"></div>
                <div class="f">
                    <div class="f_1"></div>
                    <div class="f_2"></div>
                    <div class="f_3"></div>
                    <div class="f_4"></div>
                    <div class="f_5"></div>
                    <div class="f_6"></div>
                </div>
                <div class="g">
                    <div class="g_1"></div>
                    <div class="g_2"></div>
                    <div class="g_3"></div>
                    <div class="g_4"></div>
                </div>
                <div class="h"></div>
                <div class="i">
                    <div class="i_1"></div>
                </div>
                <div class="j">
                    <div class="j_1"></div>
                </div>
                <div class="k">
                    <div class="k_1"></div>
                </div>
                <div class="star">
                    <div class="s s_1"></div>
                    <div class="s s_2"></div>
                    <div class="s s_3"></div>
                    <div class="s s_4"></div>
                    <div class="s s_5"></div>
                    <div class="s s_6"></div>
                    <div class="s s_7"></div>
                    <div class="s s_8"></div>
                    <div class="s s_9"></div>
                    <div class="s s_10"></div>
                    <div class="s s_11"></div>
                    <div class="s s_12"></div>
                    <div class="s s_13"></div>
                    <div class="s s_14"></div>
                    <div class="s s_15"></div>
                    <div class="s s_16"></div>
                </div>
            </div>
            <div class="middle-footer">
                <div class="body-box">
                    <div class="body" :style="progressStyle"></div>
                </div>
                <div class="tip">
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import com from '@/pages/mind/views/token/index.vue'
    import bcg from '@/pages/middle/views/index/components/bcg.vue'

    export default {
        name: '',
        extends: com,
        props: {
        },
        data() {
            return {
                moduleList: [
                    {class: 'm4', routeName: 'task'}, // 练功房
                    {class: 'm5', routeName: 'cabin'}, // 名师讲堂
                    {class: 'm6', routeName: 'palace'}, // 知识海岛
                    {class: 'm7', routeName: 'game'}, // AI自习室
                    {class: 'm8', routeName: 'center'}, // 任务中心
                ],
            };
        },
        methods: {
        },
        created() {
        },
        components: {
            bcg,
        },
        computed: {
            progressStyle(){
                let {progressData: pro} = this
                return {
                    left: `${pro * 100 - 100}%`
                }
            },
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang="less">
    .com-app-bcg .bcg-img.token{
        background: none;
    }
    .page-check.mind.middle{
        background: none;
        .bcg-shadow{
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            z-index: -1;
            filter: blur(2px);
            .token-user-box{
                .stone-box{
                    position: absolute;
                    width: 86px;
                    height: 22px;
                    right: 170px;
                    top: 6px;
                    .background-img-max(url('@{assetsUrlMiddle}/img/token/m26.png'));
                    .stone{
                        top: -3px;
                        left: -6px;
                        position: absolute;
                        width: 27px;
                        height: 28px;
                        .background-img-max(url('@{assetsUrlMiddle}/img/token/m27.png'));
                    }
                    .txt{
                        width: 53px;
                        height: 15px;
                        position: absolute;
                        top: 4px;
                        left: 25px;
                        .background-img-max(url('@{assetsUrlMiddle}/img/token/m28.png'));
                    }
                }
                .user-box-bcg{
                    width: 129px;
                    height: 22px;
                    position: absolute;
                    top: 6px;
                    right: 22px;
                    .user-box{
                        width: 129px;
                        height: 22px;
                        position: absolute;
                        .background-img-max(url('@{assetsUrlMiddle}/img/token/m29.png'));
                        z-index: 1;
                    }
                    .txt{
                        width: 102px;
                        height: 16px;
                        position: absolute;
                        top: 3px;
                        left: 21px;
                        .background-img-max(url('@{assetsUrlMiddle}/img/token/m33.png'));
                    }
                    .photo-bcg{
                        width: 38px;
                        height: 38px;
                        position: absolute;
                        top: 0px;
                        left: -19px;
                        z-index: 1;
                        .background-img-max(url('@{assetsUrlMiddle}/img/token/m30.png'));
                        .photo{
                            width: 29px;
                            height: 32px;
                            position: absolute;
                            top: 3px;
                            left: 4px;
                            .background-img-max(url('@{assetsUrlMiddle}/img/token/m32.png'));
                        }
                    }
                    .level-bcg{
                        width: 42px;
                        height: 22px;
                        position: absolute;
                        top: 16px;
                        left: 7px;
                        .background-img-max(url('@{assetsUrlMiddle}/img/token/m31.png'));
                        .txt{
                            width: 28px;
                            height: 15px;
                            position: absolute;
                            left: 12px;
                            top: 6px;
                            .background-img-max(url('@{assetsUrlMiddle}/img/token/m34.png'));
                        }
                    }
                }
            }
        }
        .middle-bcg{
            position: fixed;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto;
            width: 667px;
            height: 375px;
            z-index: -1;
            .middle-footer{
                position: absolute;
                width: 601px;
                height: 41px;
                left: 0;
                right: 0;
                bottom: 0;
                margin: 0 auto;
                .background-img-max(url('@{assetsUrlMiddle}/img/token/m23.png'));
                .body-box{
                    width: 567px;
                    height: 14px;
                    position: absolute;
                    top: 11px;
                    left: 14px;
                    overflow: hidden;
                    border-radius: 7px;
                    .body{
                        position: absolute;
                        transition: left .3s;
                        width: 100%;
                        height: 14px;
                        .background-img-max(url('@{assetsUrlMiddle}/img/token/m25.png'));
                    }
                }
                .tip{
                    width: 193px;
                    height: 16px;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: -10px;
                    margin: 0 auto;
                    .background-img-max(url('@{assetsUrlMiddle}/img/token/m24.png'));
                }
            }
            .content{
                position: absolute;
                top: 47px;
                left: 87px;
                width: 509px;
                height: 301px;
                .background-img-max(url('@{assetsUrlMiddle}/img/token/m2.png'));
                .a{
                    width: 263px;
                    height: 153px;
                    top: 55px;
                    left: 114px;
                    position: absolute;
                    .background-img-max(url('@{assetsUrlMiddle}/img/token/m3.png'));
                }
                .b{
                    width: 348px;
                    height: 72px;
                    position: absolute;
                    top: 166px;
                    left: 70px;
                    .background-img-max(url('@{assetsUrlMiddle}/img/token/m4.png'));
                    .b_1{
                        width: 287px;
                        height: 69px;
                        top: -19px;
                        left: 32px;
                        position: absolute;
                        z-index: 1;
                        .background-img-max(url('@{assetsUrlMiddle}/img/token/m5.png'));
                    }
                    .b_2{
                        bottom: -12px;
                        left: -14px;
                        position: absolute;
                        width: 102px;
                        height: 88px;
                        .background-img-max(url('@{assetsUrlMiddle}/img/token/m6.png'));
                        > .star{
                            width: 31px;
                            height: 31px;
                            position: absolute;
                            left: 2px;
                            bottom: 3px;
                            .background-img-max(url('@{assetsUrlMiddle}/img/token/m6_1.png'));
                        }
                    }
                }
                .c{
                    width: 75px;
                    height: 75px;
                    position: absolute;
                    top: 50px;
                    left: 175px;
                    .background-img-max(url('@{assetsUrlMiddle}/img/token/m7.png'));
                }
                .d{
                    width: 99px;
                    height: 99px;
                    position: absolute;
                    top: 36px;
                    left: 244px;
                    .background-img-max(url('@{assetsUrlMiddle}/img/token/m8.png'));
                }
                .e{
                    width: 346px;
                    height: 105px;
                    top: 108px;
                    left: 73px;
                    position: absolute;
                    .background-img-max(url('@{assetsUrlMiddle}/img/token/m9.png'));
                    z-index: 1;
                }
                .f{
                    width: 251px;
                    height: 81px;
                    position: absolute;
                    top: 121px;
                    left: 120px;
                    .background-img-max(url('@{assetsUrlMiddle}/img/token/m10.png'));
                    z-index: 1;
                    .f_1{
                        width: 116px;
                        height: 76px;
                        position: absolute;
                        left: 16px;
                        bottom: 22px;
                        .background-img-max(url('@{assetsUrlMiddle}/img/token/m11.png'));
                    }
                    .f_2{
                        width: 110px;
                        height: 74px;
                        position: absolute;
                        right: 12px;
                        bottom: 22px;
                        .background-img-max(url('@{assetsUrlMiddle}/img/token/m12.png'));
                        z-index: 2;
                    }
                    .f_3{
                        width: 21px;
                        height: 20px;
                        position: absolute;
                        left: 45px;
                        bottom: 11px;
                        .background-img-max(url('@{assetsUrlMiddle}/img/token/m13.png'));
                        z-index: 1;
                    }
                    .f_4{
                        width: 47px;
                        height: 34px;
                        position: absolute;
                        right: 53px;
                        bottom: 19px;
                        .background-img-max(url('@{assetsUrlMiddle}/img/token/m14.png'));
                        z-index: 1;
                    }
                    .f_6{
                        width: 22px;
                        height: 43px;
                        position: absolute;
                        right: 18px;
                        bottom: -3px;
                        .background-img-max(url('@{assetsUrlMiddle}/img/token/m15.png'));
                    }
                }
                .g{
                    position: absolute;
                    z-index: 3;
                    .g_1{
                        width: 82px;
                        height: 81px;
                        position: absolute;
                        top: 77px;
                        left: 99px;
                        .background-img-max(url('@{assetsUrlMiddle}/img/token/m16.png'));
                    }
                    .g_2{
                        width: 67px;
                        height: 82px;
                        position: absolute;
                        top: 68px;
                        left: 177px;
                        .background-img-max(url('@{assetsUrlMiddle}/img/token/m17.png'));
                    }
                    .g_3{
                        width: 68px;
                        height: 58px;
                        position: absolute;
                        top: 81px;
                        left: 242px;
                        .background-img-max(url('@{assetsUrlMiddle}/img/token/m18.png'));
                    }
                    .g_4{
                        width: 89px;
                        height: 81px;
                        position: absolute;
                        top: 79px;
                        left: 306px;
                        z-index: 0;
                        .background-img-max(url('@{assetsUrlMiddle}/img/token/m19.png'));
                    }
                }
                .h{
                    position: absolute;
                    width: 25px;
                    height: 32px;
                    top: 140px;
                    left: 307px;
                    z-index: 4;
                    .background-img-max(url('@{assetsUrlMiddle}/img/token/m14_1.png'));
                }
                .i{
                    width: 83px;
                    height: 49px;
                    position: absolute;
                    top: 79px;
                    left: 52px;
                    .background-img-max(url('@{assetsUrlMiddle}/img/token/m20.png'));
                    .i_1{
                        width: 36px;
                        height: 37px;
                        position: absolute;
                        top: 2px;
                        left: 2px;
                        .background-img-max(url('@{assetsUrlMiddle}/img/token/m20_1.png'));
                    }
                }
                .j{
                    width: 69px;
                    height: 56px;
                    position: absolute;
                    top: 64px;
                    left: 350px;
                    .background-img-max(url('@{assetsUrlMiddle}/img/token/m21.png'));
                    .j_1{
                        width: 27px;
                        height: 28px;
                        position: absolute;
                        top: 2px;
                        right: 2px;
                        .background-img-max(url('@{assetsUrlMiddle}/img/token/m21_1.png'));
                    }
                }
                .k{
                    width: 96px;
                    height: 50px;
                    position: absolute;
                    top: 87px;
                    right: 57px;
                    .background-img-max(url('@{assetsUrlMiddle}/img/token/m22.png'));
                    .k_1{
                        width: 40px;
                        height: 41px;
                        position: absolute;
                        top: 2px;
                        right: 2px;
                        .background-img-max(url('@{assetsUrlMiddle}/img/token/m22_1.png'));
                    }
                }
                .star{
                    .s{
                        position: absolute;
                        z-index: 1;
                    }
                    .s_1{
                        width: 13px;
                        height: 13px;
                        top: 139px;
                        right: 65px;
                        .background-img-max(url('@{assetsUrlMiddle}/img/token/star_1.png'));
                    }
                    .s_2{
                        width: 12px;
                        height: 12px;
                        top: 162px;
                        right: 88px;
                        .background-img-max(url('@{assetsUrlMiddle}/img/token/star_2.png'));
                    }
                    .s_3{
                        width: 10px;
                        height: 10px;
                        top: 224px;
                        right: 94px;
                        .background-img-max(url('@{assetsUrlMiddle}/img/token/star_3.png'));
                    }
                    .s_4{
                        width: 13px;
                        height: 13px;
                        top: 215px;
                        right: 104px;
                        .background-img-max(url('@{assetsUrlMiddle}/img/token/star_4.png'));
                    }
                    .s_5{
                        width: 12px;
                        height: 12px;
                        top: 220px;
                        left: 285px;
                        .background-img-max(url('@{assetsUrlMiddle}/img/token/star_5.png'));
                    }
                    .s_6{
                        width: 12px;
                        height: 12px;
                        top: 217px;
                        left: 158px;
                        .background-img-max(url('@{assetsUrlMiddle}/img/token/star_6.png'));
                    }
                    .s_7{
                        width: 13px;
                        height: 13px;
                        top: 208px;
                        left: 142px;
                        .background-img-max(url('@{assetsUrlMiddle}/img/token/star_7.png'));
                    }
                    .s_8{
                        width: 9px;
                        height: 9px;
                        top: 221px;
                        left: 117px;
                        .background-img-max(url('@{assetsUrlMiddle}/img/token/star_8.png'));
                    }
                    .s_9{
                        width: 17px;
                        height: 17px;
                        top: 58px;
                        left: 152px;
                        .background-img-max(url('@{assetsUrlMiddle}/img/token/star_9.png'));
                    }
                    .s_10{
                        width: 13px;
                        height: 13px;
                        top: 48px;
                        left: 143px;
                        .background-img-max(url('@{assetsUrlMiddle}/img/token/star_10.png'));
                    }
                    .s_11{
                        width: 8px;
                        height: 8px;
                        top: 136px;
                        left: 79px;
                        .background-img-max(url('@{assetsUrlMiddle}/img/token/star_11.png'));
                    }
                    .s_12{
                        width: 8px;
                        height: 8px;
                        top: 136px;
                        left: 79px;
                        .background-img-max(url('@{assetsUrlMiddle}/img/token/star_12.png'));
                    }
                    .s_13{
                        width: 11px;
                        height: 11px;
                        top: 124px;
                        left: 58px;
                        .background-img-max(url('@{assetsUrlMiddle}/img/token/star_13.png'));
                    }
                    .s_14{
                        width: 12px;
                        height: 12px;
                        top: 129px;
                        left: 43px;
                        .background-img-max(url('@{assetsUrlMiddle}/img/token/star_14.png'));
                    }
                    .s_15{
                        width: 12px;
                        height: 12px;
                        top: 102px;
                        left: 37px;
                        .background-img-max(url('@{assetsUrlMiddle}/img/token/star_15.png'));
                    }
                    .s_16{
                        width: 17px;
                        height: 17px;
                        top: 149px;
                        right: 52px;
                        .background-img-max(url('@{assetsUrlMiddle}/img/token/star_16.png'));
                    }
                }
            }
        }
    }
</style>